.loginBackground {
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
}
.loginContainer {
  margin: auto;
  display: flex;
  flex-direction: column;
  background-color: black;
  padding: 30px;
  width: 250px;
  height: 250px;
  border: 1px solid orange;
  z-index: 10;

  a {
    background-color: #ff008c;
    margin-top: 40px;
    color: white !important;
  }
}
.logo {
  color: white;
  margin: 20px;
  margin-top: 5px;
  text-align: center;
}
.videoBackground {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  height: 100%;
  width: 100%;
}
