.spinner {
  margin: auto;
}
.container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.diffHighlightInformation {
  display: flex;
  margin-top: 15px;
  div {
    margin-right: 5px;
    width: 15px;
    height: 15px;
    margin-top: 4px;
  }
  div:first-of-type {
    background-color: #0000fe;
  }
  div:nth-of-type(2) {
    background-color: #00fe81;
  }
  span {
    margin-right: 5px;
  }
}
