.container {
  display: flex;
}
.spinner {
  padding-left: 10px;
}
.information {
  padding-left: 10px;
  margin-top: -2px;
  margin-left: 4px;
}
