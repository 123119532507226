@import "../../custom.scss";

.button {
  user-select: none;
  background: none;
  border: 1px solid lightgrey;
  border-radius: 3px;
  padding: 0px 15px;
  color: #0f5b99;
}
.button[data-primary="true"] {
  color: white;
  background-color: #ff008c;
}
button:disabled {
  color: grey;
}
button[data-primary="true"]:disabled {
  background-color: #e8e8e8;
}
.button:hover {
  color: $cato-highlight;
}
.button[data-primary="true"]:hover {
  color: $cato-highlight-hover;
  background-color: #d90076;
}
.button:disabled:hover {
  color: grey;
}
