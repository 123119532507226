.thumbnail {
  background-size: contain;
  background-position: center;
  background-color: black;
  background-repeat: no-repeat;
  border: 1px solid #dddddd;
}
.thumbnailPlaceholder {
  display: flex;
  > svg {
    margin: auto;
    color: #818181;
  }
}
