@import "../../custom.scss";

.arrowButton {
  user-select: none;
  background: none;
  border: none;
}
.arrowButton:disabled {
  color: grey;
}
.arrowButton:hover {
  color: $cato-highlight;
}
.arrowButton:disabled:hover {
  color: grey;
}
