@import "../../custom.scss";
$border-color: #d0d0d0;

.header {
  background-color: $cato-black;
  height: 60px;
  display: flex;
  align-content: flex-start;
}
.logo {
  color: white;
  margin: 11px 35px;
}
.logoCato {
  color: white;
}
