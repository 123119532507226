.failureMessageBox {
  background-color: #f9ecee;
  color: #cc3219;
  padding: 12px 16px;
  font-weight: bold;
  border-radius: 3px;
  margin-bottom: 20px;
}
.messageBoxBody {
  background-color: #ecf9f9;
  color: #000000;
  padding: 12px 16px;
  font-weight: bold;
  border-radius: 3px;
  margin-bottom: 20px;
}
.messageText {
  margin-left: 8px;
}
