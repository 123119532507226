.projectInformation {
  width: 850px;
  margin: auto;
  margin-top: 40px;
  height: 50px;
  padding: 0 25px;
  h1 {
    font-size: 45px;
  }
  p {
    margin: 0 25px;
  }
}
.projectInformationSkeleton {
  display: flex;
  align-items: center;
  height: 50px;
}
