.terminalBackground {
  background-color: black;
  padding: 15px;
  font-size: 14px;
  color: white;
  font-family: "Fira Mono", monospace;
  max-width: 1000px;
}

.terminalContent {
  color: white;
}
