.filterControlContainer {
  div {
    margin-bottom: 3px;
  }
  margin-bottom: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.checkboxContainer {
}
.failureReasonFilterControls {
  display: flex;
  label {
    margin: auto;
  }
  select {
    margin-left: 10px;
    width: auto;
  }
}
