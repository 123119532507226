@import "../../custom.scss";
.testResultComparisonResult {
  display: flex;
  align-self: center;
  margin-left: 44px;
  table {
    margin-right: 30px;
    margin-top: 5px;
    margin-bottom: 5px;
    tr {
      height: 35px;
      td:first-child {
        padding-right: 10px;
        font-weight: bolder;
      }
    }
    tr.buttonRow {
      display: flex;
      height: 26px;
    }
  }
}

.infoBox {
  margin-bottom: 20px;
}

.methodInput {
  width: 100px !important;
}
.thresholdInput {
  width: 100px;
  border: 1px solid lightgrey;
  border-radius: 3px;
  height: 26px;
  padding-top: 0px;
}
.spinner {
  padding-left: 10px;
}
.information {
  padding-left: 10px;
  margin-top: -2px;
  margin-left: 4px;
}
