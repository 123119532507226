.tooltipCatcher {
  padding-top: 2px;
  padding-bottom: 2px;
}
.progressOuter {
  width: 100%;
  height: 4px;
  background-color: #efefef;
  border-radius: 3px;
}
.progressBar {
  background-color: #007bff;
  height: 100%;
  border-radius: 3px;
}
