.projectsViewContainer {
  margin-top: 150px;
}

.projectsView {
  display: flex;
  max-width: 1332px;
  max-height: 100%;
  margin: 0px auto 10px auto;
  flex-wrap: wrap;
  min-height: 132px;
}

.projectsViewProjectComponent {
  margin: 10px;
}
.centered {
  margin: auto;
}

.archivedProjectsButton {
  min-height: 0;
  margin-top: 50px;
}
