@import "../../../../custom";
.sideMenu {
  width: 180px;
  height: calc(100vh - 60px);
  background-color: #171717;
  li {
    margin: 10px;
    margin-left: 12px;
  }
  ul {
    > li:nth-child(1) {
      margin-left: -8px;
      a {
        color: #a0a0a0;
      }
    }
  }

  a {
    text-decoration: none;
    color: white;
  }
  a:visited {
    text-decoration: none;
    color: white;
  }
  a:hover {
    color: $cato-highlight !important;
    text-decoration: none;
  }
}
.active {
  a {
    color: $cato-highlight !important;
  }
}
