.runSummary {
  margin: 50px;
  > div {
    margin-bottom: 20px;
  }
}
.progressBar {
  border-radius: 3px;
  display: flex;
  border: 1px solid #c5c5c5;
  > div {
    color: white;
    height: 25px;
  }
  div:nth-child(4) {
    background-color: #bfbfbf;
  }
  div:nth-child(3) {
    background-color: orange;
  }
  div:nth-child(1) {
    background-color: greenyellow;
  }
  div:nth-child(2) {
    background-color: red;
  }
}
