@import "../../../../custom";
.suiteListEntry {
  height: 40px;
  border-top: 1px solid #dadada;
  display: flex;
  flex-direction: row;
  > span {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 20px;
  }
  > span:nth-child(1) {
    margin-left: 10px;
    cursor: pointer;
  }
  > span:nth-child(2) {
    margin-left: 10px;
  }
}
.suiteListEntryContent {
  padding-left: 40px;
  > div {
    height: 35px;
    border-top: 1px solid #dadada;
    display: flex;
    flex-direction: row;
    > span {
      margin-top: auto;
      margin-bottom: auto;
      margin-left: 10px;
    }
    > span:nth-child(4) {
      margin-right: 10px;
      margin-left: auto;
      align-self: end;
      font-size: 14px;
      color: $cato-dimmed;
    }
  }
  > div:hover {
    background-color: $cato-highlight-hover;
    cursor: pointer;
  }
}
.active {
  background-color: $cato-highlight;
}
.active:hover {
  background-color: $cato-highlight !important;
}
