.closeButtonContainer {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-bottom: 15px;
}
.modal {
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

.buttonNoShadowOnFocus:focus {
  box-shadow: none !important;
}
