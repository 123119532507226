.infoBoxValue {
  display: block;
  font-weight: bolder;
}

.infoBoxElement {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-right: 40px;
  margin-left: 40px;
}
