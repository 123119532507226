@import "../../custom.scss";
.paginationControlContainer {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
}
.controls {
  display: flex;
  svg {
    font-size: 13px;
    margin-top: -2px;
  }
  button {
    user-select: none;
    background: none;
    border: none;
    color: #0f5b99;
  }
  button:disabled {
    color: grey;
  }
  button:hover {
    color: $cato-highlight;
  }
  button:disabled:hover {
    color: grey;
  }
}
.elementsOnPage {
  margin-right: 10px;
}
.disabled {
  color: grey;
  &:hover {
    cursor: default;
  }
}
