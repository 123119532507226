@import "../../../../custom";

.projectCard {
  font-size: 22px;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.projectCardImage {
  width: 300px;
  height: 160px;
}
.projectCardMenu {
  background-color: white;
  width: 190px;
  height: 70px;
  box-shadow: 0px 2px 27px 6px rgba(0, 0, 0, 0.15);
}
.projectCardMenuItem {
  height: 35px;
}
.projectCardMenuItem:hover {
  cursor: pointer;
  background-color: $cato-highlight-hover;
}
