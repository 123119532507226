.imgCompContainer {
  display: flex;
  margin: auto;
  background-color: black;
  position: relative;
}

.imgCompContainer img {
  max-width: 100% !important;
}

.imgCompContainer div[style] {
}

.imageSizeCalculator {
  display: none;
}
.spinner {
  margin: auto;
}
.referenceText {
  position: absolute;
  color: white;
  top: 8px;
  left: 16px;
  text-shadow: 0px 0px 8px rgb(0 0 0);
}
.outputText {
  text-shadow: 0px 0px 8px rgb(0 0 0);
  position: absolute;
  color: white;
  top: 8px;
  right: 16px;
}
