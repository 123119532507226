.suiteAndTestContainer {
  width: 100%;
  display: flex;
  > :nth-child(1) {
    width: 50%;
    border-right: 5px solid #eceff1;
    height: calc(100vh - 60px);
    padding-top: 20px;
  }
  > :nth-child(2) {
    width: 50%;
    padding: 20px;
    padding-top: 15px;
  }
}
.noTestSelected {
  display: flex;
  height: 100vh;
}
.centered {
  margin: auto;
}
.filterControlsContainer {
  display: flex;
  justify-content: flex-start;
  padding-left: 15px;
  border-bottom: 5px solid #eceff1;
}
.scrollContainerTestsOrSuite {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 120px);
}
.scrollContainerSelectedTest {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 60px);
}
