@import "../../../../custom";
.testList {
  width: 100%;
  colgroup {
    > col:nth-child(1) {
      width: 0px;
    }
    > col:nth-child(2) {
      width: 0px;
    }
    > col:nth-child(3) {
      width: 0px;
    }
    > col:nth-child(4) {
      width: 0px;
    }
  }

  tr {
    height: 40px;
  }
  tr:hover {
    background-color: $cato-highlight-hover;
    cursor: pointer;
    user-select: none;
  }
  td {
    padding-left: 20px;
    border-top: 1px solid $cato-highlight-hover;
    border-bottom: 1px solid $cato-highlight-hover;
    white-space: nowrap;
  }
  td:nth-child(6) {
    text-align: end;
    padding-right: 10px;
    color: $cato-dimmed;
    font-size: 14px;
  }
}
.active {
  background-color: $cato-highlight;
}
.active:hover {
  background-color: $cato-highlight !important;
}
.loading {
  p {
    margin-bottom: 1px;
  }
}

.noTestPlaceholder {
  margin: auto;
}
